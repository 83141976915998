import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  Create,
  required,
  ImageInput,
  ImageField,
  DateField,
  SimpleShowLayout,
  SelectInput,
} from "react-admin";
import SimpleArray from "../../utils/getImages";
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import { Grid } from "@material-ui/core";
import MyRichTextInput from "../../utils/MyRichTextInput";

export const AboutusList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit" size="medium">
      <TextField source="id" label="ID" />
      <TextField source="title_pl" label="Tytuł PL" />
      <TextField source="title_en" label="Tytuł EN" />
      <DateField source="addedAt" label="Utworzony" />
      <EditButton basePath="/aboutus" label="Edytuj" />
      <DeleteButton basePath="/aboutus" label="Usuń" />
    </Datagrid>
  </List>
);

export const AboutusEdit = (props) => (
  <Edit title="Edytuj info o nas" {...props}>
    <TabbedForm>
      <FormTab label="Ustawienia">
        <Grid container spacing={2}>
          <Grid item xs="4">
            <SelectInput
              source="type"
              fullWidth
              label="Typ strefy"
              validate={required()}
              choices={[
                { id: "normal", name: "Normalny tekst" },
                { id: "quote", name: "Cytat" },
              ]}
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <TextInput source="title_pl" />
          </Grid>
        </Grid>
        <MyRichTextInput
          source="description_pl"
          label="Opis po Polsku"
          fullWidth
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <TextInput source="title_en" />
          </Grid>
        </Grid>
        <MyRichTextInput
          source="description_en"
          label="Opis po Angielsku"
          fullWidth
        />
      </FormTab>

      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput source="mainImg" label="List" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Gallery"
              accept="image/*"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <SimpleShowLayout fullWidth>
              <SimpleArray source="images" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const AboutusCreate = (props) => (
  <Create title="Utwórz nową kategorię o nas" {...props}>
    <TabbedForm>
      <FormTab label="Ustawienia">
        <Grid container spacing={2}>
          <Grid item xs="4">
            <SelectInput
              source="type"
              fullWidth
              label="Typ strefy"
              validate={required()}
              choices={[
                { id: "normal", name: "Normalny tekst" },
                { id: "quote", name: "Cytat" },
              ]}
            />
          </Grid>
        </Grid>
      </FormTab>

      <FormTab label="Język Polski">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <TextInput source="title_pl" />
          </Grid>
        </Grid>
        <MyRichTextInput
          source="description_pl"
          label="Opis po Polsku"
          fullWidth
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <TextInput source="title_en" />
          </Grid>
        </Grid>
        <MyRichTextInput
          source="description_en"
          label="Opis po Angielsku"
          fullWidth
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput source="mainImg" label="List" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Gallery"
              accept="image/*"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Create>
);
