import { fetchUtils } from "react-admin";
import restServerProvider from "ra-data-json-server";

const servicesHost = "https://api.atrustfall.com";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("auth-token");
  // add your own headers here
  options.headers.set("auth-token", token);

  return fetchUtils.fetchJson(url, options);
};

const dataRestProvider = restServerProvider(servicesHost, httpClient);

let formData;

function getElements(resource, params) {
  formData = new FormData();
  switch (resource) {
    case "news": {
      formData.append("title_pl", params.data.title_pl);
      formData.append("title_en", params.data.title_en);
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("added_at", params.data.added_at);

      //images
      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }
    case "dogs": {
      formData.append("name", params.data.name);
      formData.append("pedigreename", params.data.pedigreename);
      formData.append("mothername", params.data.mothername);
      formData.append("fathername", params.data.fathername);
      formData.append("owner", params.data.owner);
      formData.append("breeder", params.data.breeder);
      formData.append("gender", params.data.gender);
      formData.append("birthDate", params.data.birthDate);
      formData.append("imported", params.data.imported);
      formData.append("tests_pl", params.data.tests_pl);
      formData.append("tests_en", params.data.tests_en);
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("pedigreeLink", params.data.pedigreeLink);
      formData.append("growth", params.data.growth);
      formData.append("weight", params.data.weight);
      formData.append("tail_pl", params.data.tail_pl);
      formData.append("tail_en", params.data.tail_en);

      if (params.data.litters) {
        let items = [];
        params.data.litters.forEach((item) => {
          items.push(item);
        });
        formData.append("litters", JSON.stringify(items));
      }

      //images
      if (params.data.mainImg) {
        formData.append("mainImg", params.data.mainImg.rawFile);
      }
      if (params.data.mainImgRight) {
        formData.append("mainImgRight", params.data.mainImgRight.rawFile);
      }
      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }
    case "litters": {
      formData.append("name", params.data.name);
      formData.append("birthDate", params.data.birthDate);
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("parents", params.data.parents);
      formData.append("parentsHome", params.data.parentsHome);

      //images
      if (params.data.mainImg) {
        formData.append("mainImg", params.data.mainImg.rawFile);
      }
      if (params.data.panorama) {
        formData.append("panorama", params.data.panorama.rawFile);
      }
      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }
    case "aboutus": {
      formData.append("title_pl", params.data.title_pl);
      formData.append("title_en", params.data.title_en);
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("type", params.data.type);

      if (params.data.mainImg) {
        formData.append("mainImg", params.data.mainImg.rawFile);
      }

      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }

      break;
    }
    case "aboutbreed": {
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("title_pl", params.data.title_pl);
      formData.append("title_en", params.data.title_en);
      formData.append("type", params.data.type);

      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }

    case "mainpage": {
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("title_pl", params.data.title_pl);
      formData.append("title_en", params.data.title_en);
      formData.append("type", params.data.type);

      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }

    case "puppies": {
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("name", params.data.name);
      formData.append("litter", params.data.litter);

      if (params.data.mainImg) {
        formData.append("mainImg", params.data.mainImg.rawFile);
      }

      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }

    case "files": {
      if (params.data.image) {
        formData.append("image", params.data.image.rawFile);
      }
      break;
    }

    default: {
      break;
    }
  }
}

const myDataProvider = {
  ...dataRestProvider,
  create: (resource, params) => {
    getElements(resource, params);

    return httpClient(`${servicesHost}/${resource}`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },
  update: (resource, params) => {
    getElements(resource, params);

    return httpClient(`${servicesHost}/${resource}/${params.id}`, {
      method: "PUT",
      body: formData,
    }).then(() => ({
      data: { ...params.data },
    }));
  },
};

export default myDataProvider;
