import { Grid } from "@material-ui/core";
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import {
  Datagrid,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  List,
  TabbedForm,
  TextField,
  TextInput,
  ImageField,
  ImageInput,
  Create,
  required,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import ImageDeleteField from "../../utils/getImages";

export const PuppiesList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit" size="medium">
      <TextField source="id" label="ID" />
      <TextField source="name" label="Nazwa" />
      <EditButton basePath="/puppies" label="Edytuj" />
      <DeleteButton basePath="/puppies" label="Usuń" />
    </Datagrid>
  </List>
);

export const PuppyEdit = (props) => (
  <Edit {...props} title="Edytuj szczeniaka">
    <TabbedForm>
      <FormTab label="Informacje o szczeniaku">
        <Grid container spacing={2}>
          <Grid item xs="4">
            <TextInput
              source="name"
              label="Nazwa"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs="4">
            <ReferenceInput
              source="litter"
              reference="litters"
              label="Miot"
              fullWidth
            >
              <SelectInput optionText="name" label="Miot" />
            </ReferenceInput>
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <RichTextInput
          fullWidth
          source="description_pl"
          label="Opis szczeniaka po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <RichTextInput
          fullWidth
          source="description_en"
          label="Opis szczeniaka po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2} fullWidth>
          <Grid item xs="12">
            <ImageInput
              source="mainImg"
              label="Zdjęcie Główne"
              accept="image/*"
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Galeria"
              accept="image/*"
              multiple
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageDeleteField source="images" />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const PuppyCreate = (props) => (
  <Create {...props} title="Stwórz nowe szczenię">
    <TabbedForm>
      <FormTab label="Informacje o szczeniaku">
        <Grid container spacing={2}>
          <Grid item xs="4">
            <TextInput
              source="name"
              label="Nazwa"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs="3">
            <ReferenceInput
              source="litter"
              reference="litters"
              label="Miot"
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <RichTextInput
          fullWidth
          source="description_pl"
          label="Opis szczeniaka po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <RichTextInput
          fullWidth
          source="description_en"
          label="Opis szczeniaka po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container fullWidth>
          <Grid item mr="10px">
            <ImageInput
              source="mainImg"
              label="Zdjęcie Główne"
              accept="image/*"
              fullWidth
              validate={required()}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container fullWidth>
          <Grid item m="10px">
            <ImageInput
              source="gallery"
              label="Galeria"
              accept="image/*"
              fullWidth
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Create>
);
