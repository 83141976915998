import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  ColorButtons,
  AlignmentButtons,
  LinkButtons,
  ClearButtons,
  useTiptapEditor,
  DefaultEditorOptions,
} from "ra-input-rich-text";
import FontFamily from "@tiptap/extension-font-family";
import { useState } from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import FontFamilyIcon from "@mui/icons-material/Rtt";

const MyEditorOptions = {
  ...DefaultEditorOptions,
  extensions: [...DefaultEditorOptions.extensions, FontFamily],
};

const fontOptions = [
  "Ponomar",
  "Roboto",
  "Open Sans",
  "Poppins",
  "Playfair Display",
  "Lora",
  "DM Serif Text",
  "Lato",
  "Lobster",
  "Luxurious Roman",
  "Abhaya Libre",
  "Yuji Mai",
];

const MyRichTextInputToolbar = ({ source, ...props }) => {
  const editor = useTiptapEditor();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFontChange = (font) => {
    editor.chain().focus().setFontFamily(font).run();
    handleClose();
  };

  return (
    <RichTextInputToolbar {...props}>
      <LevelSelect />
      <FormatButtons />
      <ColorButtons />
      <AlignmentButtons />
      <LinkButtons />
      <ClearButtons />
      <IconButton onClick={handleClick}>
        <FontFamilyIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {fontOptions.map((font) => (
          <MenuItem
            key={font}
            onClick={() => handleFontChange(font)}
            style={{ fontFamily: font }}
          >
            {font}
          </MenuItem>
        ))}
      </Menu>
    </RichTextInputToolbar>
  );
};

const MyRichTextInput = (props) => (
  <RichTextInput
    source={props.source}
    label={props.label || "Rich Text"}
    editorOptions={MyEditorOptions}
    toolbar={<MyRichTextInputToolbar source={props.source} />}
    {...props}
  />
);

export default MyRichTextInput;
